
import { defineComponent, ref } from "vue";
import BasicInfo from "@/views/VehicleLicense/SamplingDetail/components/basicInfo.vue";
import OperRecords from "@/views/VehicleLicense/SamplingDetail/components/operRecords.vue";
import RewisedRecords from "@/views/VehicleLicense/SamplingDetail/components/rewisedRecords.vue";

export default defineComponent({
  components: {
    BasicInfo,
    OperRecords,
    RewisedRecords
  },
  setup() {
    const activeKey = ref<string>("1");
    const changeTab = (event: string) => {
      const dom = document.getElementsByClassName("ant-table-body")[
        ~~event - 1
      ];
      if (dom) {
        dom.scrollTo(0, 0);
      }
    };
    return {
      activeKey,
      changeTab
    };
  }
});
