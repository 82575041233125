import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34ee6142"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overviewCon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_basic_info = _resolveComponent("basic-info")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_oper_records = _resolveComponent("oper-records")!
  const _component_rewised_records = _resolveComponent("rewised-records")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumb, {
      dataSource: [],
      class: "titleDC"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeTab($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "Basic Info"
          }, {
            default: _withCtx(() => [
              (_ctx.activeKey == '1')
                ? (_openBlock(), _createBlock(_component_basic_info, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_a_tab_pane, {
            key: "2",
            tab: "Operation Records"
          }, {
            default: _withCtx(() => [
              (_ctx.activeKey == '2')
                ? (_openBlock(), _createBlock(_component_oper_records, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_a_tab_pane, {
            key: "3",
            tab: "Revised Records"
          }, {
            default: _withCtx(() => [
              (_ctx.activeKey == '3')
                ? (_openBlock(), _createBlock(_component_rewised_records, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeKey"])
    ])
  ], 64))
}