import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  fetchPostPic,
  fetchGetMess,
} from "../index";
export const getOverviewNum = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/sicco/count", { params });
};
export const getSearchResult = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/criteriaquery", params);
};
// 标记数据抽样
export const markSampling = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/sampling", params);
};
export const getManualSearchResult = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/manualSampling", params);
};
export const getRandomSearchResult = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/randomSampling", params);
};
export const getNewSearchResult = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/sample", params);
};
export const getBasicInfo = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/sicco/basicInfo", params);
};
export const getOperationRecords = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/dealer/operatorLog", params);
};
export const getRevisedRecords = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/dealer/modifiyRecord", params);
};
export const updateCheckInfo = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/checkInfo", params);
};
export const getCommentList = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/sicco/comment", params);
};
export const addComment = (params = {}): Promise<any> => {
  return fetchPut(`/rvapi/vl/sicco/comment`, params);
};
export const deleteComment = (id: number): Promise<any> => {
  return fetchDelete(`/rvapi/vl/sicco/comment?id=${id}`);
};
// 上传车辆核查结果文件
export const uploadFileData = (
  uploadConfig = {},
  batchNo = "",
  params = {}
): Promise<any> => {
  return fetchPostPic(
    "/rvapi/vl/sicco/upload?batchNo=" + batchNo,
    params,
    uploadConfig
  );
};
// 上传车辆核查结果文件返回结果列表数据
export const getUploadFileResult = (params = {}): Promise<any> => {
  return fetchGetMess("/rvapi/vl/sicco/uploadResult", params);
};
// 取消上传车辆核查结果文件
export const cancelUploadFile = (batchNo = ""): Promise<any> => {
  return fetchPostPic("/rvapi/vl/sicco/cancelUpload?batchNo=" + batchNo);
};
// 新车行驶证核查结果查询
export const getCheckingSearchResult = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/dealer/queryCheckingResult", params);
};
//单车提交
export const batchSiccoSumbit = (
  vinNo: string,
  dealerCode = ""
): Promise<string> => {
  return fetchPostPic(
    `/rvapi/vl/sicco/submit?vinNo=${vinNo}&dealerCode=${dealerCode}`
  );
};
//Search
export const getSumbitSearch = (params: {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/submitSearch", params);
};
//batch sumbit
export const batchSumbitToSipo = (params: {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/batchSubmit", params);
};
//getoverview
export const getOverviewNumSumbit = (params: {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/checkedAndUnsubmittedCount", params);
};
//获取行驶证信息
export const getLicenseInfo = (
  vinNo: "",
  dealerCode = "",
  params = {}
): Promise<any> => {
  return fetchGetMess(
    `/rvapi/vl/dealer/getLicenseInfo?vinNo=${vinNo}&dealerCode=${dealerCode}`,
    params
  );
};
//上传行驶证
export const uploadLicenseFile = (
  params: {},
  vinNo = "",
  dealerCode = ""
): Promise<any> => {
  return fetchPostPic(
    `/rvapi/vl/license/uploadLicense?vinNo=${vinNo}&dealerCode=${dealerCode}`,
    params,
    {
      headers: {
        "code-handler": "ignore",
      },
    }
  );
};
//获取行驶证图片
export const getLicensePicture = (params = {}): Promise<any> => {
  return fetchGet("/rvapi/vl/deale/licenseView", { params });
};
//申述保存
export const saveLicenseInfo = (params: {}): Promise<any> => {
  return fetchPost("/rvapi/vl/dealer/saveAppealInfo", params);
};
//申诉提交
export const sumbitLicenseInfo = (params: {}): Promise<any> => {
  return fetchPost("/rvapi/vl/dealer/appealSubmit", params);
};
//申述文件上传
export const uploadAppealItem = (
  params: {},
  vinNo = "",
  dealerCode = ""
): Promise<any> => {
  return fetchPostPic(
    `/rvapi/vl/license/appealFileUpload?vinNo=${vinNo}&dealerCode=${dealerCode}`,
    params
  );
};
//申诉文件删除
export const deleteAppealItem = (id: any): Promise<any> => {
  return fetchGetMess("/rvapi/vl/license/deleteAppealFile?id=" + id);
};
//获取batch opertion提示信息参数
export const getBatchOpertionMsg = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/vl/sicco/getBatchOpertionMsg", params);
  // return Promise.resolve({ approvedNo: 100, rejectNo: 1, totalNo: 1 });
};
