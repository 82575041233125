
import { defineComponent, reactive, ref } from "vue";
import { getRevisedRecords } from "@/API/vl/vlSICCOChecking";
import setColumnWidth from "@/utils/rv/setColumnWidth";

const columns = [
  { title: "操作角色", dataIndex: "opRole", width: 150 },
  { title: "原值", dataIndex: "opOldValue", width: 150 },
  { title: "更新值", dataIndex: "opNewValue", width: 150 },
  { title: "操作时间", dataIndex: "opTime", width: 150 }
];
const tableHeight = window.innerHeight - 220;
export default defineComponent({
  setup() {
    const vinNo = sessionStorage.getItem("samplingCheckingVinNo");
    const dealerCode = sessionStorage.getItem("samplingCheckingDealerCode");
    // 表格展示
    const tableData = ref([]);
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0,
    });

    const getTableData = () => {
      const params = {
        vinNo: vinNo,
        dealerCode,
        current: pagination.currentPage,
        size: pagination.pageSize
      }
      getRevisedRecords({params}).then((res: any) => {
        tableData.value = res.content;
        pagination.total = res.totalElements;
        const customerColumn = ['opRole', 'opOldValue', 'opNewValue'];
        tableData.value.length && Object.assign(columns, setColumnWidth(columns, tableData.value, customerColumn));
      });
    }

    getTableData();
    
    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };

    return {
      columns,
      status,
      tableData,
      pagination,
      getTableData,
      pageChange,
      sizeChange,
      tableHeight
    };
  },
});
